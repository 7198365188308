<template>
  <vue-final-modal
    class="referral-transfer-modal"
    :focus-trap="false"
  >
    <div class="referral-transfer-modal__container">
      <div class="referral-transfer-modal__top">
        <div class="referral-transfer-modal__header">
          <span class="referral-transfer-modal__title">
            {{ referralTransferData?.transferGameBalance }}
          </span>
          <button
            class="referral-transfer-modal__close-btn"
            @click="onCloseModal"
          >
            <atomic-icon id="close" class="referral-transfer-modal__close-icon" />
          </button>
        </div>

        <div class="referral-transfer-modal__form">
          <form-input-display
            :label="referralTransferData?.transferBalance ?? ''"
            :value="formattedRealBalance"
            :img-url="`${iconUrl}/${account?.currency}.svg`"
            :is-open="false"
            is-hide-arrow
          />

          <form-input-number
            v-model:value="amountValueInput"
            :is-required="false"
            :hint="fieldHint"
            :label="referralTransferData?.amount ?? ''"
            :label-right="labelRight"
            name="TransferSum"
            :min="referralTransferData?.minAmountValue"
            :max="account?.referralBalance"
            :placeholder="referralTransferData?.enterAmount"
            :default-value="defaultInputSum.amount"
            :currency="account?.currency"
            @submit="handleTransfer"
          />
        </div>
      </div>

      <div class="referral-transfer-modal__bottom">
        <button-base
          type="gray"
          size="sm"
          class="referral-transfer-modal__btn referral-transfer-modal__btn_close"
          @click="onCloseModal"
        >
          {{ referralTransferData?.close }}
        </button-base>

        <button-base
          type="primary"
          size="sm"
          class="referral-transfer-modal__btn"
          :is-disabled="disableTransferBtn"
          @click="handleTransfer(amountValueInput)"
        >
          <atomic-spinner :is-shown="isLockedAsyncButton" />
          {{ referralTransferData?.transfer }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>


<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import { ModalName } from "@skeleton/consts/modals";

  import type { IProfileWallet } from '~/types';

  const contentParams = {
    contentKey: 'profileWalletContent',
    contentRoute: ['profile', 'wallet'],
    isPage: false,
  };

  const { getContentData } = useContentLogic<IProfileWallet>(contentParams);

  const { data: walletContent } = await useLazyAsyncData(getContentData);

  const { closeModal } = useModalStore();
  const { iconUrl } = useDynamicIcon('currency');
  const { accounts } = storeToRefs(useWalletStore());
  const { formatBalance } = useProjectMethods();
  const { handleTransfer, isLockedAsyncButton } = useReferralTransfer();

  const amountValueInput = ref('');

  const referralTransferData = computed(() =>
    walletContent.value?.currentLocaleData?.referralTransfer ??
    walletContent.value?.defaultLocaleData?.referralTransfer
  );

  const onCloseModal = (): void => {
    closeModal(ModalName.REFERRAL_TRANSFER);
  };

  const fieldHint = computed(() => ({
    message: `${referralTransferData?.value?.minAmount} ${referralTransferData?.value?.minAmountValue} ${account.value?.currency}`,
    variant: ''
  }));

  const defaultInputSum = computed(() => formatBalance(account.value?.currency, referralTransferData.value?.minAmountValue));

  const account = computed(() => accounts.value.find(account => account.currency === 'USDT'));

  const formattedRealBalance = computed(() =>
    account.value?.realBalance ? account.value.realBalance.toFixed(2) : '0.00');

  const labelRight = computed(() => `${referralTransferData?.value?.availableTransfer
  } ${account.value?.referralBalance?.toFixed(2) ?? 0} ${account.value?.currency}`);

  const disableTransferBtn = computed(() => !amountValueInput.value || !account.value?.referralBalance);
</script>

<style src="~/assets/styles/components/modal/referral-transfer.scss" lang="scss" />
