import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';
import { ModalName } from '@skeleton/consts/modals';

export const useReferralTransfer = () => {
  const { showAlert } = useLayoutStore();
  const { closeModal } = useModalStore();
  const walletStore = useWalletStore();
  const { alertsData, defaultLocaleAlertsData } = storeToRefs(useGlobalStore());

  const isLockedAsyncButton = ref(false);
  const handleTransfer = async (amountValueInput: string | number): Promise<void> => {
    const amountInput = Number(amountValueInput);
    if (!amountInput) return;
    try {
      isLockedAsyncButton.value = true;
      await useApiAuthInstance('/api/payment/referrals/transfer-referral-balance', {
        method: 'POST',
        body: { amount: amountInput },
      });
      await closeModal(ModalName.REFERRAL_TRANSFER);
      await walletStore.getUserAccounts();
      showAlert(
        alertsData.value?.referrals?.transferCompleted ||
          defaultLocaleAlertsData.value?.referrals?.transferCompleted,
      );
    } catch (error:any) {
      if (error.response?.status === 422) {
        showAlert(
          alertsData.value?.referrals?.transferError ||
            defaultLocaleAlertsData.value?.referrals?.transferError,
        );
      } else {
        showAlert(alertsData.value?.global?.somethingWrong
          || defaultLocaleAlertsData.value?.global?.somethingWrong);
      }
    } finally {
      isLockedAsyncButton.value = false;
    }
  };

  return {
    isLockedAsyncButton,
    handleTransfer
  };
};
